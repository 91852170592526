import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { EDIT_USER, LOAD_USER, LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, editUserSuccess, loadUserSuccess, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";

const fireBaseBackend = getFirebaseBackend();

function* onLoginUser({ payload: { user, router } }) {
  
  try {
    const response = yield call(
      fireBaseBackend.loginUser,
      user.email,
      user.password
    );
    yield put(loginSuccess(response));
    if (router.location.state.redirectTo) {
      router.navigate(router.location.state.redirectTo);
    } else {
      router.navigate("/");
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* onLoadUser({ payload }) {
  yield put(loadUserSuccess(payload));
}

function* onLogoutUser({ payload: { navigate } }) {
  try {
    const response = yield call(fireBaseBackend.logout);
    yield put(logoutUserSuccess(response));
    navigate('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* onSocialLogin({ payload: { type, router } }) {
  try {
    const fireBaseBackend = getFirebaseBackend();
    const response = yield call(fireBaseBackend.socialLoginUser, type);
    if (response) {
      router.navigate("/");
    } else {
      router.navigate("/login");
    }
    yield put(loginSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* onEditUser({ payload: { user } }) {
  try {
    const response = yield call(
      fireBaseBackend.editProfile,
      user.displayName,
    )
    yield put(editUserSuccess(response))      
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, onLoginUser);
  yield takeEvery(LOAD_USER, onLoadUser);
  yield takeLatest(SOCIAL_LOGIN, onSocialLogin);
  yield takeEvery(LOGOUT_USER, onLogoutUser);
  yield takeEvery(EDIT_USER, onEditUser);
}

export default authSaga;
